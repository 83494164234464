<template>
  <v-app-bar
    app
    :class="classCordova"
    ref="navbar"
    class="appbar navbar-app white"
    :flat="$vuetify.breakpoint.smAndDown"
    clipped-left
    v-scroll="onScroll"
    v-resize="onResize"
    :height="
      applicationConfig && !alreadyShowedNewsBanner
        ? $vuetify.breakpoint.smAndDown
          ? '60'
          : '35'
        : '0'
    "
    :extension-height="68"
  >
    <template v-if="applicationConfig && !alreadyShowedNewsBanner">
      <template v-if="applicationConfig && !alreadyShowedNewsBanner">
        <category-block
          v-if="applicationConfig && !alreadyShowedNewsBanner"
          :target="applicationConfig"
          position="header"
          class="category-block category-block-header w-100 h-100"
          :container="false"
        />
        <!-- <v-btn
          class="close-header-banner-btn close-news"
          icon
          color="default"
          @click="closeBannerNews()"
          :aria-label="$t('common.close')"
        >
          <v-icon>$close</v-icon>
        </v-btn> -->
      </template>
    </template>
    <!-- top menu -->
    <template v-slot:extension>
      <v-row no-gutters :align="'center'">
        <v-col
          cols="1"
          class="justify-left align-center"
          v-if="$routerHistory.hasPrevious()"
        >
          <router-link
            class="router-back"
            active-class="noop"
            :to="{ path: $routerHistory.previous().path }"
          >
            <v-btn class="no-border" aria-label="Torna Indietro" icon>
              <v-icon class="default--text back-arrow">$arrowBack</v-icon>
            </v-btn>
          </router-link>
        </v-col>
        <v-col
          cols="2"
          md="2"
          lg="2"
          xl="1"
          class="justify-center align-center"
        >
          <!-- <div
          class="d-flex flex-column justify-space-between"
          
        > -->
          <v-btn
            v-if="site === 'site'"
            aria-label="Apri menu istituzionale"
            icon
            tile
            class="secondary no-border rounded"
            style="right: -5px"
            @click.prevent.stop="drawerLeft = !drawerLeft"
          >
            <v-icon color="primary">$menu</v-icon>
          </v-btn>
          <v-btn
            v-else
            aria-label="Apri menu categorie"
            icon
            tile
            class="secondary no-border rounded"
            style="right: -5px"
            @click.prevent.stop="drawerLeft = !drawerLeft"
          >
            <v-icon color="primary">
              $menuEcommerce
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          :cols="$routerHistory.hasPrevious() ? 7 : 8"
          md="7"
          class="d-flex flex-row justify-center align-center px-md-2 "
          style="flex-wrap: nowrap;"
        >
          <div
            style="min-width: 100px; max-width: 100%;"
            class="flex-grow-1 flex-shrink-0 pl-2"
          >
            <SearchInput />
          </div>
        </v-col>
        <v-col cols="2" md="2" class="d-flex justify-end justify-md-end">
          <v-btn
            class="mr-2 d-none d-md-inline-flex border rounded"
            @click.stop="openAddressSelector"
            medium
            icon
            tile
          >
            <v-icon color="primary">$location</v-icon>
          </v-btn>
          <v-btn
            class="mr-2 d-none d-md-inline-flex border rounded"
            @click.stop="openTimeslotSelector"
            medium
            tile
            icon
          >
            <v-icon color="primary">$editcalendar</v-icon>
          </v-btn>
          <v-badge
            :content="totalItems"
            :value="totalItems > 0"
            :key="totalItems"
            transition="bounce"
            color="secondary shopping-cart-icon"
            overlap
            offset-x="26"
            offset-y="22"
            class="primary--text font-weight-bold"
          >
            <v-btn icon tile class="border rounded" :to="{ name: 'Checkout' }">
              <v-icon color="primary">$cart</v-icon>
            </v-btn>
          </v-badge>
        </v-col>
      </v-row>

      <v-progress-linear
        :active="showOverlay"
        :indeterminate="showOverlay"
        absolute
        bottom
        color="primary"
      ></v-progress-linear>
    </template>
  </v-app-bar>
</template>
<style lang="scss">
.shopping-cart-icon {
  color: var(--v-primary-base) !important;
}

@media screen and (max-width: 1360px) {
  .username {
    display: none !important;
  }
}

.v-main {
  background-color: $background-color-white;
}

.v-app-bar--fixed {
  background-color: #fcfcfc !important;
  position: fixed;
  top: 0;
  z-index: 11 !important;
  width: 100%;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-app-bar--fixed {
    position: fixed;
    top: 0;
    z-index: 11 !important;
    height: $statusbarheightxs;
    width: 100%;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

// .appbar {
//   height: fit-content !important;
// }

.drawer-btn {
  height: 60px !important;
  width: 60px !important;
  label {
    font-size: 9px;
  }
}

.back-arrow {
  font-size: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

.router-back {
  text-decoration: none;
}

.router-back,
.noop {
  .theme--light.v-btn:before {
    background: $white !important;
  }
}
</style>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import SearchInput from "@/components/navigation/SearchInput.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login, cartInfo],
  components: { SearchInput },
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      bannerTopKey: 0
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    goBack() {
      this.$router.go(-1);
    },
    closeBannerNews() {
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = {};
          bannersTopShowingDate[headerNews.categoryBlockId] = new Date(
            Date.now() + 3600 * 1000 * 24
          ).toISOString();
          window.sessionStorage["bannersTopShowingDate"] = JSON.stringify(
            bannersTopShowingDate
          );
        }
      }
      this.bannerTopKey++;
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    alreadyShowedNewsBanner() {
      this.bannerTopKey;
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = window.sessionStorage[
            "bannersTopShowingDate"
          ]
            ? JSON.parse(window.sessionStorage["bannersTopShowingDate"])
            : {};
          let catBlockId = headerNews.categoryBlockId;

          let nextOpeningDate = bannersTopShowingDate[catBlockId];
          let today = new Date();
          if (
            Object.prototype.hasOwnProperty.call(
              bannersTopShowingDate,
              catBlockId
            ) &&
            Date.parse(nextOpeningDate) > Date.parse(today)
          ) {
            // there is banner but close already clicked
            return true;
          }

          // show banner
          return false;
        } else {
          // there is no proposal -> do not show news banner
          return true;
        }
      }
      // there is no banner to show
      return true;
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
};
</script>
